/* eslint-disable */
import React, { Component } from 'react'
import {
  applyThemes,
  setSessionStatus,
  toggleSnackbar,
} from "../../actions/index";

export class WXLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  async componentDidMount() {
    await this.initScript()
    this.onOpenLogin()
    let self = this;
    window.receiveMessageFromIndex = function (event) {
      if (event != undefined) {
        // 发送请求 event.data为微信信息对象 为对象类型再请求接口
        if (typeof event.data === 'object') {
          const WXCode = event.data.code
          self.props.wxLogin(WXCode);
        }
      }
    }
    //监听来自iframe的message事件
    window.addEventListener('message', receiveMessageFromIndex, false)
  }

  componentWillUnmount() {
    window.removeEventListener('message',receiveMessageFromIndex)
  }

  initScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
    document.head.appendChild(script);
  }

  onOpenLogin = () => {
    const timer = setTimeout(() => {
      this.initWX()
      clearTimeout(timer)
    }, 800)
  }
  // redirect_uri: encodeURIComponent('https://pan.blocktest.top/success.html'), // 扫码成功后重定向地址
  initWX() {
    new window.WxLogin({
      self_redirect: true,
      id: 'login_container', 
      appid: 'wx214df6cca860634f',
      scope: 'snsapi_login', // 写死，网页应用暂时只支持这个值
      redirect_uri: encodeURIComponent(window.location.origin + '/success.html'), // 扫码成功后重定向地址
      state: "" + (new Date()).getTime(), //随机字符串
      style: 'black',
    })
    this.setState({ isLoading: false })
  }

  render() {
    return (
	    <div onClick={this.onOpenLogin} className='' style={{ display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",height:"100%"}}>
	      <div style={{ fontSize:"16",color:"#16b998",letterSpacing:"0",textAlign:"center",marginBottom:"20px",marginTop:"10px"}}> 请打开手机微信扫描二维码确认登录 </div>
        <div id="login_container" style={{ textAlign: 'center' ,width:"300px",height:"300px",overflow:"hidden"}} />
	    </div>
    )
  }
}

export default WXLogin;