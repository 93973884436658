import React, { useCallback,useEffect,useState } from "react";
import {
    Checkbox,
    FormControl,
    makeStyles,
    TextField,
} from "@material-ui/core";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
} from "@material-ui/core";
import { toggleSnackbar } from "../../actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useRouteMatch } from "react-router";
import API from "../../middleware/Api";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LockIcon from "@material-ui/icons/Lock";
import TimerIcon from "@material-ui/icons/Timer";
import CasinoIcon from "@material-ui/icons/Casino";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import ToggleIcon from "material-ui-toggle-icon";
import { sizeToString } from "../../utils";
import ClipboardJS from 'clipboard';
import copy from 'copy-to-clipboard';

const useStyles = makeStyles((theme) => ({
    widthAnimation: {},
    shareUrl: {
        minWidth: "400px",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.light,
        position: "absolute",
        top: "50%",
        left: "50%",
    },
    flexCenter: {
        alignItems: "center",
    },
    noFlex: {
        display: "block",
    },
    scoreCalc: {
        marginTop: 10,
    },
}));

const ExpansionPanel = withStyles({
    root: {
        border: "0px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        padding: 0,
        "&$expanded": {},
    },
    content: {
        margin: 0,
        display: "initial",
        "&$expanded": {
            margin: "0 0",
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: 24,
        backgroundColor: theme.palette.background.default,
    },
}))(MuiExpansionPanelDetails);

export default function CreatShare(props) {
    const [text, setText] = useState('1111111')
    new ClipboardJS('.share_copy', {
        text: function () {
          return text
        }
      })
    const copyData = useCallback( () => {
        setText('123esdsds')
      }, [])

    // const initScript = () => {
    //     const script = document.createElement('script')
    //     script.type = 'text/javascript'
    //     script.async = true
    //     script.src = 'https://cdn.jsdelivr.net/npm/clipboard@2.0.6/dist/clipboard.min.js'//这个代表的是script 的src
    //     document.head.appendChild(script)
    //  }
    const dispatch = useDispatch();
    const classes = useStyles();
    const math = useRouteMatch();

    const ToggleSnackbar = useCallback(
        (vertical, horizontal, msg, color) =>
            dispatch(toggleSnackbar(vertical, horizontal, msg, color)),
        [dispatch]
    );

    const scoreEnabled = useSelector((state) => state.siteConfig.score_enabled);
    const scoreRate = useSelector((state) => state.siteConfig.share_score_rate);
    const shareScoreprice = useSelector((state) => state.siteConfig.share_score_price);

    const [expanded, setExpanded] = React.useState(false);
    const [shareURL, setShareURL] = React.useState("");
    const [values, setValues] = React.useState({
        password: "",
        downloads: 1,
        expires: 24 * 3600,
        showPassword: false,
        score: 0,
        showScore_show_: 0,
        get_score_: 0,
        get_k_rmb_:0,

    });
    const [shareOption, setShareOption] = React.useState({
        password: false,
        expire: false,
        score: false,
        preview: true,
        isQZ:false,
    });
    
    // const isQZ = (prop)=> {
    //     if((props.selected.length > 0 && props.selected[0].size >= 104857600)){
    //         return true;
    //     }else{
    //         return false;
    //     }
    // };
    const GetShareScore = (prop)=> {
        if(props.selected.length > 0 && props.selected[0].size >= 104857600){
            const size_to_M = parseInt(props.selected[0].size / (1024*1024));
            //100M 是1积分
            const showScore_show = parseInt(size_to_M / 100);
            //计算超出部分的积分
            let out_score = 0 //超出的积分
            let get_score = 0 //赚取的积分
            let get_k_rmb = 0 //赚取的千次RMB
            if(parseFloat(values.score) > showScore_show){
                out_score = parseFloat(values.score) - showScore_show
                get_score = parseFloat((out_score * scoreRate) /100).toFixed(2)
                //计算千次下载积分和RMB
                get_score = 1000 * get_score
                get_k_rmb = get_score * shareScoreprice
                get_k_rmb = (Number(get_k_rmb) / 100).toFixed(2)
            }
            const html = '每人次下载最低需支付' +showScore_show+ '积分，超出部分可获得' +scoreRate+ '%分成<br>预计千次下载可获得' +get_score+ '积分，约' +get_k_rmb+ '元'
            const html_ = {__html:html};
            return <div dangerouslySetInnerHTML={html_}></div> ;
        }else{
            //计算
            let get_score = 0 //积分
            get_score = parseFloat((values.score * scoreRate) /100).toFixed(2)
            let get_k_rmb = 0 //赚取的千次RMB
            //计算千次下载积分和RMB
            get_score = 1000 * get_score
            get_k_rmb = get_score * shareScoreprice
            get_k_rmb = (Number(get_k_rmb) / 100).toFixed(2)
            const html = '每人次下载最低需支付0积分，超出部分可获得' +scoreRate+ '%分成<br>预计千次下载可获得' +get_score+ '积分，约' +get_k_rmb+ '元'
            const html_ = {__html:html};
            return <div dangerouslySetInnerHTML={html_}></div> ;
        }
    };
    const handleChange = (prop) => (event) => {
        // 输入密码
        if (prop === "password") {
            if (event.target.value === "") {
                setShareOption({ ...shareOption, password: false });
            } else {
                setShareOption({ ...shareOption, password: true });
            }
        }

        // 输入积分
        if (prop === "score") {
            if (event.target.value == "0") {
                setShareOption({ ...shareOption, score: false });
            } else {
                setShareOption({ ...shareOption, score: true });
            }
            setValues({ ...values, score: event.target.value });

            // if(props.selected[0].size >= 104857600){
            //     //计算时看大于100M 的不允许小于最小积分
            //     const size_to_M = sizeToString(props.selected[0].size);
            //     const size_int = parseInt(size_to_M,10);
            //     //100M 是1积分
            //     const showScore = parseFloat(size_int / 100);
            //     if(event.target.value < showScore){
            //         setValues({ ...values, score: showScore });
            //     }else{
            //         setValues({ ...values, score: event.target.value });
            //     }
            // }else{
            //     setValues({ ...values, score: event.target.value });
            // }
        }else{
            setValues({ ...values, [prop]: event.target.value });
        }
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const randomPassword = () => {
        setShareOption({ ...shareOption, password: true });
        setValues({
            ...values,
            password: Math.random().toString(36).substr(2).slice(2, 8),
            showPassword: true,
        });
    };

    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleCheck = (prop) => () => {
        if(prop === "score" && shareOption["isQZ"]){
            return;
        }
        if (!shareOption[prop]) {
            handleExpand(prop)(null, true);
        }
        if (prop === "password" && shareOption[prop]) {
            setValues({
                ...values,
                password: "",
            });
        }
        if (prop === "score" && shareOption[prop]) {
            setValues({
                ...values,
                score: 0,
            });
        }
        setShareOption({ ...shareOption, [prop]: !shareOption[prop] });
    };

    const onClose = () => {
        props.onClose();
        setTimeout(() => {
            setShareURL("");
        }, 500);
    };
    useEffect(() => {
        
        // initScript()
        // document.querySelector('.share_copy').click()
      }, [])
    useEffect(() => {
        if(props.selected.length > 0){
            
            // this.clipboard = window.Qiniu.ClipboardEx("#share_copy");
            //恢复初始化组件 isQZ
            if(props.selected[0].size >= 104857600){
                setShareOption({ ...shareOption, isQZ: true });
                const size_to_M = parseInt(props.selected[0].size / (1024*1024));
                //100M 是1积分
                const showScore = parseInt(size_to_M / 100);
                setValues({ ...values, score: showScore });
            }else{
                setShareOption({ ...shareOption, isQZ: false });
                setValues({ ...values, score: 0 });
            }
        }
        
    },[props]);
    function copyToClip(contentArray, message) {
        let contents = "";
        for (let i = 0; i < contentArray.length; i++) {
            contents += contentArray[i] + "\n";
        }
        const textarea = document.createElement('textarea');
        textarea.value = contents;
        document.body.appendChild(textarea);
        textarea.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
        }
        document.body.removeChild(textarea);
        if (message == null) {
            alert("复制成功");
        } else{
            alert(message);
        }
    }
    const copyLinkAndPwd = (e) => {
        e.preventDefault();
        let copyValue = "分享地址:" + shareURL;
        if (values.password !=""){
            copyValue = copyValue + "\r\n密码:" + values.password
        }
        copy(copyValue);
        ToggleSnackbar("top", "right", "复制成功", "success");
    }
    const submitShare = (e) => {
        e.preventDefault();
        //积分判断
        if(props.selected[0].size >= 104857600){
            //计算时看大于100M 的不允许小于最小积分
            const size_to_M = parseInt(props.selected[0].size / (1024*1024));
            //100M 是1积分
            const showScore = parseInt(size_to_M / 100);//最小积分
            if(values.score < showScore){
                ToggleSnackbar("top", "right", "该文件下载积分最小为" + (showScore), "warning");
                return;
            }
        }
        props.setModalsLoading(true);
        const submitFormBody = {
            id: props.selected[0].id,
            is_dir: props.selected[0].type === "dir",
            password: values.password,
            downloads: shareOption.expire ? values.downloads : -1,
            expire: values.expires,
            score: parseFloat(values.score),
            // score: 0,
            preview: shareOption.preview,
        };

        API.post("/share", submitFormBody)
            .then((response) => {
                setShareURL(response.data);
                // setValues({
                //     password: "",
                //     downloads: 1,
                //     expires: 24 * 3600,
                //     showPassword: false,
                //     score: 0,
                // });
                // setShareOption({
                //     password: false,
                //     expire: false,
                //     score: false,
                // });
                props.setModalsLoading(false);
            })
            .catch((error) => {
                ToggleSnackbar("top", "right", error.message, "error");
                props.setModalsLoading(false);
            });
    };

    const handleFocus = (event) => event.target.select();
    
    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            className={classes.widthAnimation}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">创建分享链接</DialogTitle>

            {shareURL === "" && (
                <>
                    <Divider />
                    <List>
                        <ExpansionPanel
                            expanded={expanded === "password"}
                            onChange={handleExpand("password")}
                        >
                            <ExpansionPanelSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <ListItem button>
                                    <ListItemIcon>
                                        <LockIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="使用密码保护" />
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            checked={shareOption.password}
                                            onChange={handleCheck("password")}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <FormControl
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                >
                                    <InputLabel htmlFor="filled-adornment-password">
                                        分享密码
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        id="outlined-adornment-password"
                                        type={
                                            values.showPassword
                                                ? "text"
                                                : "password"
                                        }
                                        value={values.password}
                                        onChange={handleChange("password")}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Tooltip title="随机生成">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={randomPassword}
                                                        edge="end"
                                                    >
                                                        <CasinoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                >
                                                    <ToggleIcon
                                                        on={values.showPassword}
                                                        onIcon={<Visibility />}
                                                        offIcon={
                                                            <VisibilityOff />
                                                        }
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel
                            expanded={expanded === "expire"}
                            onChange={handleExpand("expire")}
                        >
                            <ExpansionPanelSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <ListItem button>
                                    <ListItemIcon>
                                        <TimerIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="自动过期" />
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            checked={shareOption.expire}
                                            onChange={handleCheck("expire")}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails
                                className={classes.flexCenter}
                            >
                                <FormControl
                                    style={{
                                        marginRight: 10,
                                    }}
                                >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.downloads}
                                        onChange={handleChange("downloads")}
                                    >
                                        <MenuItem value={1}>1 次下载</MenuItem>
                                        <MenuItem value={2}>2 次下载</MenuItem>
                                        <MenuItem value={3}>3 次下载</MenuItem>
                                        <MenuItem value={4}>4 次下载</MenuItem>
                                        <MenuItem value={5}>5 次下载</MenuItem>
                                        <MenuItem value={20}>
                                            20 次下载
                                        </MenuItem>
                                        <MenuItem value={50}>
                                            50 次下载
                                        </MenuItem>
                                        <MenuItem value={100}>
                                            100 次下载
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <Typography>或者</Typography>
                                <FormControl
                                    style={{
                                        marginRight: 10,
                                        marginLeft: 10,
                                    }}
                                >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={values.expires}
                                        onChange={handleChange("expires")}
                                    >
                                        <MenuItem value={300}>5 分钟</MenuItem>
                                        <MenuItem value={3600}>1 小时</MenuItem>
                                        <MenuItem value={24 * 3600}>
                                            1 天
                                        </MenuItem>
                                        <MenuItem value={7 * 24 * 3600}>
                                            7 天
                                        </MenuItem>
                                        <MenuItem value={30 * 24 * 3600}>
                                            30 天
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <Typography>后过期</Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        {scoreEnabled && (
                            <ExpansionPanel
                                expanded={shareOption.isQZ || expanded === "score"}
                                onChange={handleExpand("score")}
                            >
                                <ExpansionPanelSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <ListItem button>
                                        <ListItemIcon>
                                            <AccountBalanceWalletIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="付积分下载" />
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                readOnly={!shareOption.isQZ}
                                                checked={shareOption.isQZ || shareOption.score}
                                                onChange={handleCheck("score")}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </ExpansionPanelSummary>

                                <ExpansionPanelDetails
                                    className={classes.noFlex}
                                >
                                    <FormControl
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                    >
                                        <InputLabel htmlFor="filled-adornment-password">
                                            每人次下载需支付的积分
                                        </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            id="outlined-adornment-password"
                                            type="number"
                                            inputProps={{ min: 0 }}
                                            value={values.score}
                                            onChange={handleChange("score")}
                                            labelWidth={180}
                                        />
                                    </FormControl>
                                    {values.score !== 0 && scoreRate !== "100" && (
                                        <Typography
                                            variant="body2"
                                            className={classes.scoreCalc}
                                        >
                                            {GetShareScore()}
                                            {/* {"预计每人次下载可到账 " +
                                                parseFloat((values.score * scoreRate) /
                                                100).toFixed(2) +
                                                " 积分"} */}
                                        </Typography>
                                    )}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )}
                    </List>
                    <Divider />
                </>
            )}
            {shareURL !== "" && (
                <DialogContent>
                    <TextField
                        // onFocus={handleFocus}
                        autoFocus
                        id = "share_url_id"
                        inputProps={{ readonly: true }}
                        label="分享链接"
                        value={shareURL}
                        variant="outlined"
                        fullWidth
                    />
                    {values.password !== "" && (
                        <Typography style={{
                                        marginTop: 25,
                                    }}
                                    >
                            <TextField
                                // onFocus={handleFocus}
                                id = "share_pas_id"
                                autoFocus
                                inputProps={{ readonly: true }}
                                label="分享密码"
                                value={values.password}
                                variant="outlined"
                                fullWidth
                            />
                        </Typography>
                     )}
                </DialogContent>
            )}

            <DialogActions>
            <Button onClick={onClose}>关闭</Button>
                {shareURL !== "" && (
                    <div className={classes.wrapper}>
                        <Button
                            // onClick={copyData}
                            onClick={copyLinkAndPwd}
                            className = "share_copy"
                            // id = "share_copy"
                            color="secondary"
                            disabled={props.modalsLoading}
                            // data-clipboard-action="copy"
                        >
                            复制链接
                        </Button>
                    </div>
                )}
                

                {shareURL === "" && (
                    <div className={classes.wrapper}>
                        <Button
                            onClick={submitShare}
                            color="secondary"
                            disabled={props.modalsLoading}
                            
                        >
                            创建分享链接
                            {props.modalsLoading && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                        </Button>
                    </div>
                )}
            </DialogActions>
        </Dialog>
    );
}
