import React, { useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import SizeInput from "../Common/SizeInput";
import { makeStyles } from "@material-ui/core/styles";
import API from "../../../middleware/Api";

const useStyles = makeStyles(() => ({
    formContainer: {
        margin: "8px 0 8px 0",
    },
}));

export default function Withdrawals({ open, onClose, onSubmit, maxShareSorce,WithdrawalsSorce }) {
    const classes = useStyles();
    const [shareSorce, setShareSorce] = useState(0);
    const [withdrawalsSorce, setWithdrawalsSorce] = useState(0);
    const [minSorce, setminSorce] = useState(0);


    useEffect(() => {
        setShareSorce(maxShareSorce)
        setWithdrawalsSorce(WithdrawalsSorce)
        setminSorce(WithdrawalsSorce)
    }, [maxShareSorce,WithdrawalsSorce]);

    const handleChange = (name) => (event) => {

        setWithdrawalsSorce(event.target.value)
    };

    const submit = (e) => {
        e.preventDefault();
        //提交提现日志记录
        API.post("/user/withdrawalsAction/", {
            WithdrawalsScore: parseFloat(withdrawalsSorce),
        }).then((response) => {
            onSubmit("")
        }).catch((error) => {
            onSubmit(error.message)
        });

        
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xs"}
            fullWidth
        >
            <form onSubmit={submit}>
                <DialogTitle id="alert-dialog-title">
                    分享积分提现
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <div className={classes.formContainer}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="component-helper">
                                    提现积分
                                </InputLabel>
                                <Input
                                    type={"number"}
                                    inputProps={{
                                        min:minSorce,
                                        step: 1,
                                        max:maxShareSorce
                                    }}
                                    value={withdrawalsSorce}
                                    onChange={handleChange("withdrawalsSorce")}
                                    required
                                />
                                <FormHelperText id="component-helper-text">
                                    最少提积分:{minSorce}，当前可提现分享积分:{maxShareSorce}
                                </FormHelperText>
                            </FormControl>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="default">
                        取消
                    </Button>
                    <Button type={"submit"} color="primary">
                        确定
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
