/* eslint-disable */
import React, { Component,useCallback, useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import API from "../../middleware/Api";
import { toggleSnackbar } from "../../actions";
import { withRouter } from "react-router";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackbar: (vertical, horizontal, msg, color) => {
            dispatch(toggleSnackbar(vertical, horizontal, msg, color));
        },
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up("md")]: {
            marginLeft: 100,
        },
        marginBottom: 40,
    },
    form: {
        maxWidth: 400,
        marginTop: 20,
        marginBottom: 20,
    },
    formContainer: {
        [theme.breakpoints.up("md")]: {
            padding: "0px 24px 0 24px",
        },
    },
    tabForm: {
        marginTop: 20,
    },
    content: {
        padding: theme.spacing(2),
    },
    tableContainer: {
        overflowX: "auto",
        marginTop: theme.spacing(2),
    },
    navigator: {
        marginTop: 10,
    },
}));

const mapStateToProps = () => {
    return {};
};

export default function Withdrawals() {
    const classes = useStyles();
    const [ZFBZhangHao, setZFBZhangHao] = useState("");
    const [ZFBName, setZFBName] = useState("");

    const [loading, setLoading] = useState(false);

    const [CardNo, setCardNo] = useState("");
    const [CardName, setCardName] = useState("");
    const [CardAddr, setCardAddr] = useState("");
    const dispatch = useDispatch();
    const ToggleSnackbar = useCallback(
        (vertical, horizontal, msg, color) =>
            dispatch(toggleSnackbar(vertical, horizontal, msg, color)),
        [dispatch]
    );
    const handleChange = (name) => (event) => {
        if(name === "ZFBZhangHao"){
            setZFBZhangHao(event.target.value)
        }
        if(name === "ZFBName"){
            setZFBName(event.target.value)
        }
        if(name === "CardNo"){
            setCardNo(event.target.value)
        }
        if(name === "CardName"){
            setCardName(event.target.value)
        }
        if(name === "CardAddr"){
            setCardAddr(event.target.value)
        }
    };
    useEffect(() => {
        API.get(
            "/user/withdrawals/"
            )
            .then((response) => {
                setZFBZhangHao(response.data.ZFBZhangHao)
                setZFBName(response.data.ZFBName)
                setCardNo(response.data.CardNo)
                setCardName(response.data.CardName)
                setCardAddr(response.data.CardAddr)
            })
            .catch((error) => {
                ToggleSnackbar(
                    "top",
                    "right",
                    error.message,
                    "error"
                );
            });
        // eslint-disable-next-line
    }, []);
    const saveWithdrawals = () => {
        if((ZFBZhangHao === "" || ZFBZhangHao == null) && (ZFBName === "" || ZFBName == null) && 
        (CardNo === "" || CardNo == null) && (CardName === "" || CardName == null) && (CardAddr === "" || CardAddr == null)){
            ToggleSnackbar(
                "top",
                "right",
                "请先填写提现信息",
                "warning"
            );
            return
        }
        API.post("/user/setWithdrawals/", {
            ZhiFuBaoZhangHao: ZFBZhangHao,
            ZhiFuBaoRealName: ZFBName,
            CardNo: CardNo,
            CardName: CardName,
            CardAddr: CardAddr,
        }).then((response) => {
            ToggleSnackbar("top", "right", "保存成功", "success");
        }).catch((error) => {
            ToggleSnackbar("top", "right", error.message, "error");
        });
    };


    return (
        <div>
                <Paper square>
                <Tabs>
                    <Tab label="提现收款信息设置" />
                </Tabs>
                    <div className={useStyles().content}>
                        <div>
                            <div className={classes.root}>
                                <Typography variant="h6" gutterBottom>
                                    支付宝设置
                                </Typography>
                                <div className={classes.formContainer}>
                                    <div className={classes.form}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="component-helper">
                                                支付宝账号
                                            </InputLabel>
                                            <Input
                                                value={ZFBZhangHao}
                                                onChange={handleChange(
                                                    "ZFBZhangHao"
                                                )}
                                                required
                                            />
                                            <FormHelperText id="component-helper-text">
                                                提现收款的支付宝账号,请仔细核对
                                            </FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className={classes.form}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="component-helper">
                                                支付宝账号所属人姓名
                                            </InputLabel>
                                            <Input
                                                value={ZFBName}
                                                onChange={handleChange(
                                                    "ZFBName"
                                                )}
                                                required
                                            />
                                            <FormHelperText id="component-helper-text">
                                                提现收款的支付宝账号所属人姓名,请仔细核对
                                            </FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.root}>
                                <Typography variant="h6" gutterBottom>
                                    银行卡设置
                                </Typography>
                                <div className={classes.formContainer}>
                                    <div className={classes.form}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="component-helper">
                                                银行卡号
                                            </InputLabel>
                                            <Input
                                                value={CardNo}
                                                onChange={handleChange(
                                                    "CardNo"
                                                )}
                                                required
                                            />
                                            <FormHelperText id="component-helper-text">
                                                提现收款的银行卡号,请仔细核对
                                            </FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className={classes.form}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="component-helper">
                                                银行卡所属人姓名
                                            </InputLabel>
                                            <Input
                                                value={CardName}
                                                onChange={handleChange(
                                                    "CardName"
                                                )}
                                                required
                                            />
                                            <FormHelperText id="component-helper-text">
                                                提现收款的银行卡号所属人姓名,请仔细核对
                                            </FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div className={classes.form}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="component-helper">
                                                银行卡开户行
                                            </InputLabel>
                                            <Input
                                                value={CardAddr}
                                                onChange={handleChange(
                                                    "CardAddr"
                                                )}
                                                required
                                            />
                                            <FormHelperText id="component-helper-text">
                                                银行卡的开户行地址,请仔细核对
                                            </FormHelperText>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.root}>
                                <Button
                                    disabled={loading}
                                    onClick={() => {
                                        saveWithdrawals();
                                    }}
                                    type={"submit"}
                                    variant={"contained"}
                                    color={"primary"}
                                >
                                    保存
                                </Button>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
    );
}

// const Withdrawals = connect(
//     mapStateToProps,
//     mapDispatchToProps
// )((withRouter(WithdrawalsCompoment)));

// export default Withdrawals;
