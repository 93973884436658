import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleSnackbar } from "../../actions";
import API from "../../middleware/Api";
import { sizeToString } from "../../utils";
import Withdrawals from "../Admin/Dialogs/Withdrawals";


import {
    withStyles,
    Paper,
    Avatar,
    Typography,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    Button,
} from "@material-ui/core";
import { withRouter } from "react-router";
import Pagination from "@material-ui/lab/Pagination";
import { formatLocalTime } from "../../utils/datetime";
import Link from "@material-ui/core/Link";


const styles = (theme) => ({
    layout: {
        width: "auto",
        marginTop: "50px",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: "30px",
        [theme.breakpoints.up("sm")]: {
            width: 780,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    userNav: {
        height: "270px",
        backgroundColor: theme.palette.primary.main,
        padding: "20px 20px 2em",
        backgroundImage:
            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='" +
            theme.palette.primary.light.replace("#", "%23") +
            "' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='" +
            theme.palette.primary.dark.replace("#", "%23") +
            "' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='" +
            theme.palette.secondary.main.replace("#", "%23") +
            "' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='" +
            theme.palette.secondary.dark.replace("#", "%23") +
            "' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='" +
            theme.palette.secondary.light.replace("#", "%23") +
            "' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='" +
            theme.palette.secondary.main.replace("#", "%23") +
            "' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='" +
            theme.palette.primary.dark.replace("#", "%23") +
            "' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='" +
            theme.palette.primary.main.replace("#", "%23") +
            "' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='" +
            theme.palette.secondary.light.replace("#", "%23") +
            "' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='" +
            theme.palette.secondary.dark.replace("#", "%23") +
            "' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='" +
            theme.palette.secondary.main.replace("#", "%23") +
            "' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='" +
            theme.palette.secondary.dark.replace("#", "%23") +
            "' points='943 900 1210 900 971 687'/%3E%3C/svg%3E\")",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
    },
    avatarContainer: {
        height: "80px",
        width: "80px",
        borderRaidus: "50%",
        margin: "auto",
        marginTop: "50px",
        boxShadow:
            "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
        border: "2px solid #fff",
    },
    nickName: {
        width: "200px",
        margin: "auto",
        textAlign: "center",
        marginTop: "1px",
        fontSize: "25px",
        color: "#ffffff",
        opacity: "0.81",
    },
    th: {
        minWidth: "106px",
    },
    mobileHide: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    tableLink: {
        cursor: "pointer",
    },
    navigator: {
        padding: theme.spacing(2),
    },
    pageInfo: {
        marginTop: "14px",
        marginLeft: "23px",
    },
    infoItem: {
        paddingLeft: "46px!important",
        paddingBottom: "20px!important",
    },
    infoContainer: {
        marginTop: "30px",
    },
    tableContainer: {
        overflowX: "auto",
    },
    loadMore: {
        textAlign: "right",
        marginTop: "20px",
        marginBottom: "40px",
    },
});
const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackbar: (vertical, horizontal, msg, color) => {
            dispatch(toggleSnackbar(vertical, horizontal, msg, color));
        },
    };
};

class ProfileCompoment extends Component {
    state = {
        listType: 0,
        shareList: [],
        page: 1,
        user: null,
        total: 0,
        withdrawals:false,
        shareScoreWithdrawals : 0,
        withdrawalsSorce : 0,
        withdrawalsList: [],
        withdrawalsTotal:0
    };

    handleChange = (event, listType) => {
        this.setState({
            shareList: [],
            total: 0,
        });
        this.setState({ listType });
        if (listType === 2) {
            this.loadList(1, "hot");
        } else if (listType === 1) {
            this.loadList(1, "default");
        }else if (listType === 3) {
            this.loadListSharedown(1, "shareDownload");
        }else if (listType === 4) {
            this.loadWithdrawalsList(1);
        }
    };

    componentDidMount = () => {
        this.loadList(1, "default");
    };
    submitShare = (e) => {
        // this.props.toggleSnackbar("top", "right", "敬请期待", "warning");
        API.get(
            "/user/withdrawalsPrev/"
            )
            .then((response) => {
                this.setState({
                    shareScoreWithdrawals: response.data.ShareScore,
                    withdrawalsSorce : response.data.WithdrawalsSorce
                }); 
                this.setState({
                    withdrawals: true
                });
            })
            .catch((error) => {
                this.props.toggleSnackbar(
                    "top",
                    "right",
                    error.message,
                    "error"
                );
            });
    };
    loadList = (page, order) => {
        this.setState({
            shareList: [],
        });
        API.get(
            "/user/profile/" +
                this.props.match.params.id +
                "?page=" +
                page +
                "&type=" +
                order
        )
            .then((response) => {
                this.setState({
                    shareList: response.data.items,
                    user: response.data.user,
                    total: response.data.total,
                }); 
            })
            .catch((error) => {
                this.props.toggleSnackbar(
                    "top",
                    "right",
                    error.message,
                    "error"
                );
            });
    };

    withdrawalsOver = (msg) => {
        this.setState({
            withdrawals: false
        });
        if (msg === ""){
            this.props.toggleSnackbar(
                "top",
                "right",
                "发起提现请求成功",
                "success"
            );
        }else{
            this.props.toggleSnackbar(
                "top",
                "right",
                msg,
                "error"
            );
        }
        
    };

    loadWithdrawalsList = (page) => {
        this.setState({
            withdrawalsList: [],
        });
        API.post("/user/listWithdrawalID", {
            page: page,
            page_size: 10,
        })
        .then((response) => {
            this.setState({
                withdrawalsList: response.data.items,
                withdrawalsTotal: response.data.total,
            }); 
        })
        .catch((error) => {
            this.props.toggleSnackbar("top", "right", error.message, "error");
        });
    };

    loadListSharedown = (page,order) => {
        this.setState({
            shareList: [],
        });
        API.get(
            "/user/profile/" +
                this.props.match.params.id +
                "?page=" +
                page +
                "&type=" +
                order
        )
            .then((response) => {
                this.setState({
                    shareList: response.data.items,
                    total: response.data.total,
                });
            })
            .catch((error) => {
                this.props.toggleSnackbar(
                    "top",
                    "right",
                    error.message,
                    "error"
                );
            });
    };
    loadNext = () => {
        this.loadList(
            this.state.page + 1,
            this.state.listType === 1 ? "default" : "hot"
        );
    };

    loadPrev = () => {
        this.loadList(
            this.state.page - 1,
            this.state.listType === 1 ? "default" : "hot"
        );
    };
    handlePageChange = (event, value) => {
        //this.state.page = value;
        this.loadList(
            value,
            this.state.listType === 1 ? "default" : "hot"
        );
    };
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.layout}>
                {this.state.user === null && <div></div>}
                {this.state.user !== null && (
                    <Paper square>
                        <div className={classes.userNav}>
                            <div>
                                <Avatar
                                    className={classes.avatarContainer}
                                    src={
                                        "/api/v3/user/avatar/" +
                                        this.state.user.id +
                                        "/l"
                                    }
                                />
                            </div>
                            <div>
                                <Typography className={classes.nickName} noWrap>
                                    {this.state.user.nick}
                                </Typography>
                            </div>
                        </div>
                        <Tabs
                            value={this.state.listType}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.handleChange}
                            centered
                        >
                            <Tab label="个人中心" />
                            <Tab label="全部分享" />
                            <Tab label="热门分享" />
                            <Tab label="已下载分享" />
                            <Tab label="提现记录" />
                        </Tabs>
                        {this.state.listType === 0 && (
                            <div className={classes.infoContainer}>
                                <Grid container spacing={24}>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            文件总数
                                        </Typography>
                                        <Typography>
                                            {this.state.user.FileCount}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            分享总数
                                        </Typography>
                                        <Typography>
                                            {this.state.user.ShareCount}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            用户组
                                        </Typography>
                                        <Typography>
                                            {this.state.user.group}
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            储存空间
                                        </Typography>
                                        <Typography>
                                            {sizeToString(this.state.user.total)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            已用空间
                                        </Typography>
                                        <Typography>
                                        {sizeToString(this.state.user.total - this.state.user.free)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            可用空间
                                        </Typography>
                                        <Typography>
                                        {sizeToString(this.state.user.free)}
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            可用积分
                                        </Typography>
                                        <Typography>
                                        {(parseFloat(this.state.user.score) + parseFloat(this.state.user.shareScore)).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            充值积分
                                        </Typography>
                                        <Typography>
                                            {(parseFloat(this.state.user.score)).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            分享收益积分
                                        </Typography>
                                        <Typography>
                                        {(parseFloat(this.state.user.shareScore)).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            
                                        </Typography>
                                        <Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                            
                                        </Typography>
                                        <Typography>
                                        <Button
                                            onClick={() => {
                                                this.props.history.push(
                                                    "/Withdrawals"
                                                );
                                            }}
                                            variant={"contained"}
                                            color="secondary">
                                            补充提现账户信息
                                        </Button>
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                        className={classes.infoItem}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                        >
                                           
                                            <Button
                                            onClick={this.submitShare}
                                            variant={"contained"}
                                            color="secondary"
                                        >
                                            积分提现
                                        </Button>
                                        </Typography>
                                        <Typography>
                                        
                                        </Typography>
                                    </Grid>
                                </Grid>
                                
                            </div>
                        )}
                        {(this.state.listType === 1 ||
                            this.state.listType === 2) && (
                            <div>
                                <div className={classes.tableContainer}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>文件名</TableCell>
                                                <TableCell
                                                    className={
                                                        classes.mobileHide
                                                    }
                                                >
                                                    分享日期
                                                </TableCell>
                                                <TableCell
                                                    className={[
                                                        classes.th,
                                                        classes.mobileHide,
                                                    ]}
                                                >
                                                    下载次数
                                                </TableCell>
                                                <TableCell
                                                    className={[
                                                        classes.th,
                                                        classes.mobileHide,
                                                    ]}
                                                >
                                                    浏览次数
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.shareList.map(
                                                (row, id) => (
                                                    <TableRow
                                                        key={id}
                                                        className={
                                                            classes.tableLink
                                                        }
                                                        onClick={() =>
                                                            this.props.history.push(
                                                                "/s/" + row.key
                                                            )
                                                        }
                                                    >
                                                        <TableCell>
                                                            <Typography>
                                                                {row.source
                                                                    ? row.source
                                                                          .name
                                                                    : "[已失效]"}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            nowrap={"nowrap"}
                                                            className={
                                                                classes.mobileHide
                                                            }
                                                        >
                                                            {formatLocalTime(
                                                                row.create_date,
                                                                "YYYY-MM-DD H:mm:ss"
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                classes.mobileHide
                                                            }
                                                        >
                                                            {row.downloads}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                classes.mobileHide
                                                            }
                                                        >
                                                            {row.views}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                {/* this.state.shareList.length !== 0 && */}
                                {
                                    this.state.listType === 1 && (
                                        <div className={classes.navigator}>
                                            <Pagination
                                                count={Math.ceil(
                                                    this.state.total / 10
                                                )}
                                                onChange={(e, v) =>
                                                    this.loadList(
                                                        v,
                                                        "default"
                                                    )
                                                }
                                                color="secondary"
                                            />
                                        </div>
                                    )}
                                    {/* this.state.shareList.length !== 0 && */}
                                    {
                                    this.state.listType === 2 && (
                                        <div className={classes.navigator}>
                                            <Pagination
                                                count={Math.ceil(
                                                    this.state.total / 10
                                                )}
                                                onChange={(e, v) =>
                                                    this.loadList(
                                                        v,
                                                        "hot"
                                                    )
                                                }
                                                color="secondary"
                                            />
                                        </div>
                                    )}
                            </div>
                        )}
                        {(this.state.listType === 3) && (
                            <div>
                                <div className={classes.tableContainer}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>文件名</TableCell>
                                                <TableCell>分享人</TableCell>
                                                <TableCell
                                                    className={
                                                        classes.mobileHide
                                                    }
                                                >
                                                    下载日期
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.shareList.map(
                                                (row, id) => (
                                                    <TableRow
                                                        key={id}
                                                        className={
                                                            classes.tableLink
                                                        }
                                                    >
                                                        <TableCell>
                                                            <Typography>
                                                                {row.sourceName}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {row.shareUserName}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            nowrap={"nowrap"}
                                                            className={
                                                                classes.mobileHide
                                                            }
                                                        >
                                                            {formatLocalTime(
                                                                row.create_date,
                                                                "YYYY-MM-DD H:mm:ss"
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                {
                                    this.state.listType === 3 && (
                                        <div className={classes.navigator}>
                                            <Pagination
                                                count={Math.ceil(
                                                    this.state.total / 10
                                                )}
                                                onChange={(e, v) =>
                                                    this.loadListSharedown(
                                                        v,
                                                        "shareDownload"
                                                    )
                                                }
                                                color="secondary"
                                            />
                                        </div>
                                    )}
                            </div>
                        )}
                        {
                           (this.state.listType === 4) && (
                            <div>
                                <div className={classes.tableContainer}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>提现积分</TableCell>
                                                <TableCell>提现积分单价</TableCell>
                                                <TableCell>提现金额</TableCell>
                                                <TableCell>提现状态</TableCell>
                                                <TableCell
                                                    className={
                                                        classes.mobileHide
                                                    }
                                                >
                                                    提现日期
                                                </TableCell>
                                                <TableCell>提现备注</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.withdrawalsList.map(
                                                (row, id) => (
                                                    <TableRow
                                                        key={id}
                                                        className={
                                                            classes.tableLink
                                                        }
                                                    >
                                                        <TableCell>
                                                            <Typography>
                                                                {row.WithdrawalsScore}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                            ￥{row.PriceFen /100}元
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                ￥{row.PriceCurrent / 100}元
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                            {row.WithdrawalsStatus === 0 && <Link href="#" underline="none" style={{color:"gray"}}>未处理</Link>}
                                                            {row.WithdrawalsStatus === 1 && <Link href="#" underline="none" style={{color:"blue"}}>提现成功</Link>}
                                                            {row.WithdrawalsStatus === 2 && <Link href="#" underline="none" style={{color:"Red"}}>提现驳回</Link>}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            nowrap={"nowrap"}
                                                            className={
                                                                classes.mobileHide
                                                            }
                                                        >
                                                            {formatLocalTime(
                                                                row.CreatedAt,
                                                                "YYYY-MM-DD H:mm:ss"
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {row.Remark}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                {
                                    this.state.listType === 4 && (
                                        <div className={classes.navigator}>
                                            <Pagination
                                                count={Math.ceil(
                                                    this.state.withdrawalsTotal / 10
                                                )}
                                                onChange={(e, v) =>
                                                    this.loadWithdrawalsList(v)
                                                }
                                                color="secondary"
                                            />
                                        </div>
                                )}
                            </div>
                        )
                        }
                    </Paper>
                )}
                <Withdrawals
                    onSubmit={this.withdrawalsOver}
                    open={this.state.withdrawals}
                    maxShareSorce={this.state.shareScoreWithdrawals}
                    WithdrawalsSorce={this.state.withdrawalsSorce}
                    onClose={() => {
                        this.setState({
                            withdrawals: false
                        });
                    }}
                />
            </div>
            
        );
    }
}

const Profile = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(withRouter(ProfileCompoment)));

export default Profile;
