import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Avatar,
    Button,
    Divider,
    FormControl,
    Input,
    InputLabel,
    Link,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";
import { toggleSnackbar } from "../../actions/index";
import { useHistory } from "react-router-dom";
import API from "../../middleware/Api";
import KeyIcon from "@material-ui/icons/VpnKeyOutlined";
import { useCaptcha } from "../../hooks/useCaptcha";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: "auto",
        marginTop: "110px",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto",
        },
        marginBottom: 110,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
            3
        )}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    captchaContainer: {
        display: "flex",
        marginTop: "10px",
    },
    captchaPlaceholder: {
        width: 200,
    },
    link: {
        marginTop: "20px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    linkSendSMS:{
        position:"absolute",
        bottom:'10px',
        right:'0px',
        color: "rgba(0,0,0,0.64)",
    },
    linkSendSMSDark:{
        position:"absolute",
        bottom:'10px',
        right:'0px',
        color: "rgba(255,255,255,1)",
    },
}));

function Reset() {
    const [input, setInput] = useState({
        phone: "",
        verCode:"",//验证码
        password: "",//密码
        password_repeat: "",//确认密码
    });
    const [loading, setLoading] = useState(false);
    const [verification, setVerification] = useState("发送验证码");
    const [verificationStatus, setVerificationStatus] = useState(true);
    const forgetCaptcha = useSelector(
        (state) => state.siteConfig.forgetCaptcha
    );
    const dispatch = useDispatch();
    const ToggleSnackbar = useCallback(
        (vertical, horizontal, msg, color) =>
            dispatch(toggleSnackbar(vertical, horizontal, msg, color)),
        [dispatch]
    );
    const ThemeType = useSelector(
        (state) => state.siteConfig.theme.palette.type
    );
    const handleInputChange = (name) => (e) => {
        setInput({
            ...input,
            [name]: e.target.value,
        });
    };

    const {
        captchaLoading,
        isValidate,
        validate,
        CaptchaRender,
        captchaRefreshRef,
        captchaParamsRef,
    } = useCaptcha();
    const history = useHistory();
    let timer
    const sendSMSCodeEvent = (e) => {
        e.preventDefault();
        //获取验证码
        if (verificationStatus){
            if (input.phone == null || input.phone == "") {
                ToggleSnackbar("top", "right", "请输入手机号码", "warning");
                return;
            }
            setVerificationStatus(false);
            setLoading(true);
            API.post("/user/SendSMS", {
                PhoneNum: input.phone,
                SMSType: "3",//找回密码
            }).then((response) => {
                    setLoading(false);
                    if (response.rawData.code === 0) {//短信发送成功
                        let timerCount = 120;
                        setVerification(timerCount+'S重试')
                        timer = setInterval(() => {    
                            setVerification(timerCount+'S重试')
                            timerCount--;
                            if(timerCount === -1){
                                clearInterval(timer);      //清除定时器
                                setVerification('发送验证码')
                                setVerificationStatus(true);
                            }
                        }, 1000);
                    } else{
                        setVerificationStatus(true);
                        //根据状态码来显示错误信息
                        ToggleSnackbar("top", "right", response.msg, "warning");
                    }
            }).catch((error) => {
                setVerificationStatus(true);
                setLoading(false);
                ToggleSnackbar("top", "right", error.message, "warning");
            });
        }
    }
    const submit = (e) => {
        e.preventDefault();
        if (input.password !== input.password_repeat) {
            ToggleSnackbar("top", "right", "两次密码输入不一致", "warning");
            return;
        }
        setLoading(true);
        if (!isValidate.current.isValidate && forgetCaptcha) {
            validate(() => submit(e), setLoading);
            return;
        }
        API.post("/user/reset", {
            userName: input.phone,
            Password: input.password,
            SMSCode: input.verCode,
        }).then(() => {
                setLoading(false);
                history.push("/login?username=" + input.phone);
                ToggleSnackbar("top", "right", "找回密码成功", "success");
        }).catch((error) => {
                setLoading(false);
                ToggleSnackbar("top", "right", error.message, "warning");
                captchaRefreshRef.current();
        });
    };

    const classes = useStyles();

    return (
        <div className={classes.layout}>
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <KeyIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    找回密码
                </Typography>
                <form className={classes.form} onSubmit={submit}>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="phone">
                        手机号码
                    </InputLabel>
                    <Input
                        id="phone"
                        name="phone_reset"
                        onChange={handleInputChange("phone")}
                        autoComplete="off"
                        value={input.phone}
                        autoFocus
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="verCode">
                        验证码
                    </InputLabel>
                    <Input
                        id="verCode"
                        type="text"
                        name="verCode_reset"
                        width = "250px"
                        onChange={
                            handleInputChange("verCode")
                        }
                        autoComplete="off"
                        value={input.verCode}
                    >
                    </Input>
                    <Link href={"/"} underline="none" className={ThemeType == "light" ? 
                        classes.linkSendSMS : classes.linkSendSMSDark} 
                        onClick={sendSMSCodeEvent}>
                        {verification}
                    </Link>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">密码</InputLabel>
                    <Input
                        name="password_reset"
                        onChange={handleInputChange("password")}
                        type="password"
                        id="password"
                        value={input.password}
                        autoComplete="off"
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">
                        确认密码
                    </InputLabel>
                    <Input
                        name="pwdRepeat_reset"
                        onChange={handleInputChange(
                            "password_repeat"
                        )}
                        type="password"
                        id="pwdRepeat"
                        value={input.password_repeat}
                        autoComplete="off"
                    />
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={
                        loading
                    }
                    className={classes.submit}
                >
                    确定
                </Button>{" "}
                </form>{" "}
                <Divider />
                <div className={classes.link}>
                    <div>
                        <Link href={"/login"}>返回登录</Link>
                    </div>
                    <div>
                        <Link href={"/signup"}>注册账号</Link>
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default Reset;
