/* eslint-disable */
import React, { Component } from 'react'

export class WXLoginBack extends Component {
  constructor(props) {
    super(props)//?s=1&f=7
    const query = this.props.location.search // '?code=123123123123&state='
    const arr = query.split('&')
    const code = arr[0].substr(6)
    window.parent.postMessage({
        code:code
    },'*'); //window.postMessage
  }
  render() {
    return (
	    <div style={{ display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",height:"100%"}}><div id="click">扫码成功，正在登录</div></div>
    )
  }
}

export default WXLoginBack;