import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {
    Avatar,
    Button,
    Divider,
    FormControl,
    Input,
    InputLabel,
    Link,
    makeStyles,
    Paper,
    rgbToHex,
    Typography,
    IconButton,
} from "@material-ui/core";
import {
    applyThemes,
    setSessionStatus,
    toggleSnackbar,
} from "../../actions/index";
import { useHistory } from "react-router-dom";
import API from "../../middleware/Api";
import Auth from "../../middleware/Auth";
import { bufferDecode, bufferEncode } from "../../utils/index";
import { enableUploaderLoad } from "../../middleware/Init";
import { Fingerprint, InfoOutlined, VpnKey } from "@material-ui/icons";
import VpnIcon from "@material-ui/icons/VpnKeyOutlined";
import { useLocation } from "react-router";
import { ICPFooter } from "../Common/ICPFooter";
import { WXLogin } from "../Common/WxLogin";
import { useCaptcha } from "../../hooks/useCaptcha";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: "auto",
        marginTop: "110px",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto",
        },
        marginBottom: 110,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
            3
        )}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    avatarSmall:{
        width:"24",
        height:"24",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    link: {
        //marginTop: "20px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    captchaContainer: {
        display: "flex",
        marginTop: "10px",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    captchaPlaceholder: {
        width: 200,
    },
    buttonContainer: {
        display: "flex",
    },
    authnLink: {
        textAlign: "center",
        marginTop: 16,
    },
    linkSendSMS:{
        position:"absolute",
        bottom:'10px',
        right:'0px',
        color: "rgba(0,0,0,0.64)",
        // fontSize:"1.05rem"
    },
    linkSendSMSDark:{
        position:"absolute",
        bottom:'10px',
        right:'0px',
        color: "rgba(255,255,255,1)",
        // fontSize:"1.05rem",
    },
    linkMargn:{
        marginRight:"10px",
        marginTop:"22px",
    },
    linkMargn2:{
        marginTop:"22px",
    }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function LoginForm() {
    const [phone, setPhone] = useState("");
    const [verification, setVerification] = useState("发送验证码");
    const [verificationStatus, setVerificationStatus] = useState(true);
    const [verCode, setVerCode] = useState("");
    const [pwd, setPwd] = useState("");
    const [loading, setLoading] = useState(false);
    const [UserPwdLogin,setUserPwdLogin] = useState(true);
    const [USMSLogin,setUSMSLogin] = useState(false);
    const [useAuthn, setUseAuthn] = useState(false);
    const [twoFA, setTwoFA] = useState(false);
    const [weChatLogin, setWeChatLogin] = useState(false);
    const [faCode, setFACode] = useState("");

    const loginCaptcha = useSelector((state) => state.siteConfig.loginCaptcha);
    const title = useSelector((state) => state.siteConfig.title);
    const QQLogin = useSelector((state) => state.siteConfig.QQLogin);
    const authn = useSelector((state) => state.siteConfig.authn);

    const dispatch = useDispatch();
    const ToggleSnackbar = useCallback(
        (vertical, horizontal, msg, color) =>
            dispatch(toggleSnackbar(vertical, horizontal, msg, color)),
        [dispatch]
    );
    const ApplyThemes = useCallback((theme) => dispatch(applyThemes(theme)), [
        dispatch,
    ]);
    const SetSessionStatus = useCallback(
        (status) => dispatch(setSessionStatus(status)),
        [dispatch]
    );
    const ThemeType = useSelector(
        (state) => state.siteConfig.theme.palette.type
    );
    const history = useHistory();
    const location = useLocation();
    const {
        captchaLoading,
        isValidate,
        validate,
        CaptchaRender,
        captchaRefreshRef,
        captchaParamsRef,
    } = useCaptcha();
    const query = useQuery();
    const classes = useStyles();
    let timer
    useEffect(() => {
        setPhone(query.get("username"));
    }, [location]);

    const sendSMSCodeEvent = (e) => {
        e.preventDefault();
        //获取验证码
        if (verificationStatus){
            if (phone == null || phone == "") {
                ToggleSnackbar("top", "right", "请输入手机号码", "warning");
                return;
            }
            setVerificationStatus(false);
            setLoading(true);
            API.post("/user/SendSMS", {
                PhoneNum: phone,
                SMSType: "1",
            }).then((response) => {
                    setLoading(false);
                    if (response.rawData.code === 0) {//短信发送成功
                        let timerCount = 120;
                        setVerification(timerCount+'S重试')
                        timer = setInterval(() => {    
                            setVerification(timerCount+'S重试')
                            timerCount--;
                            if(timerCount === -1){
                                clearInterval(timer);      //清除定时器
                                setVerification('发送验证码')
                                setVerificationStatus(true);
                            }
                        }, 1000);
                    } else{
                        setVerificationStatus(true);
                        //根据状态码来显示错误信息
                        ToggleSnackbar("top", "right", response.msg, "warning");
                    }
            }).catch((error) => {
                setVerificationStatus(true);
                setLoading(false);
                ToggleSnackbar("top", "right", error.message, "warning");
            });
        }
    }
    
    const afterLogin = (data) => {
        Auth.authenticate(data);
        // 设置用户主题色
        if (data["preferred_theme"] !== "") {
            ApplyThemes(data["preferred_theme"]);
        }
        enableUploaderLoad();
        // 设置登录状态
        SetSessionStatus(true);

        history.push("/home");
        ToggleSnackbar("top", "right", "登录成功", "success");

        localStorage.removeItem("siteConfigCache");
    };
    const wxLogin = (data) =>{
        //进行微信登录
        API.post("/user/WxLogin", {
            code: data,
          }).then((response) => {
                if (response.rawData.code === 0) {//登陆成功
                  afterLogin(response.data);
                }else{
                    //根据状态码来显示错误信息
                    ToggleSnackbar("top", "right", response.msg, "warning");
                    setWeChatLogin(false)
                }
            }).catch((error) => {
                ToggleSnackbar("top", "right", error.message, "warning");
                captchaRefreshRef.current();
                setWeChatLogin(false)
          });
    }
    const login = (e) => {
        e.preventDefault();
        setLoading(true);
        if (!isValidate.current.isValidate && loginCaptcha) {
            validate(() => login(e), setLoading);
            return;
        }
        //如果是用户名和密码登陆直接登陆，验证码登陆，等待验证码输入
        API.post("/user/session", {
            userName: phone,
            Password: pwd,
            LoginType:UserPwdLogin ? "1" : "2",//1是密码登陆 2 是验证码登陆
            SMSCode:verCode,//如果不是验证码登陆不用输入
            ...captchaParamsRef.current,
        })
            .then((response) => {
                setLoading(false);
                if (response.rawData.code === 203) {
                    setTwoFA(true);
                } else if (response.rawData.code === 0) {
                    afterLogin(response.data);
                }else{
                    //根据状态码来显示错误信息
                    ToggleSnackbar("top", "right", response.msg, "warning");
                }
            })
            .catch((error) => {
                setLoading(false);
                ToggleSnackbar("top", "right", error.message, "warning");
                captchaRefreshRef.current();
            });
    };

    const setUserPwdLoginEvent = (e) => {
        e.preventDefault();
        setUSMSLogin(false)
        setUserPwdLogin(true);
    };
    // const componentWillMount =() =>{
    //     const script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.async = false;
    //     script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
    //     document.head.appendChild(script);
    // }
    const setWeChatLoginEvent = (e) => {
        setWeChatLogin(true)
    };
    const setPhoneLoginEvent = (e) => {
        setWeChatLogin(false)
    };

    const setUSMSLoginEvent = (e) => {
        e.preventDefault();
        setUSMSLogin(true)
        setUserPwdLogin(false);
    };

    return (
        <div className={classes.layout}>
            {!weChatLogin && (
                <>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            登录 {title}
                        </Typography>
                        {/* 二维码登陆 */}
                        <div>
                            <form className={classes.form} onSubmit={login}>
                                    <FormControl margin="normal" required fullWidth>
                                            <InputLabel >
                                                手机号码
                                            </InputLabel>
                                            <Input
                                                id="phone_login"
                                                type="text"
                                                name="phone_login"
                                                onChange={(e) =>
                                                    setPhone(e.target.value)
                                                }
                                                autoComplete="off"
                                                value={phone}
                                                autoFocus
                                            />
                                        </FormControl>
                                        {UserPwdLogin && (
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="password">
                                                    密码
                                                </InputLabel>
                                                <Input
                                                    name="password_login"
                                                    onChange={(e) => setPwd(e.target.value)}
                                                    type="password"
                                                    id="password_login"
                                                    value={pwd}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        )}
                                        {USMSLogin && (
                                            <FormControl margin="normal" required fullWidth>
                                                <InputLabel htmlFor="vercode">
                                                    验证码
                                                </InputLabel>
                                                <Input
                                                    id="vercode_login"
                                                    type="text"
                                                    name="vercode_login"
                                                    onChange={(e) =>
                                                        setVerCode(e.target.value)
                                                    }
                                                    autoComplete="off"
                                                    value={verCode}
                                                    autoFocus
                                                >
                                                </Input>
                                                <Link href={"/"}    underline="none" className={ThemeType == "light" ? 
                                                                                                classes.linkSendSMS : classes.linkSendSMSDark} 
                                                                    onClick={sendSMSCodeEvent}>
                                                    {verification}
                                                    </Link>
                                            </FormControl>
                                        )}
                                        {loginCaptcha && <CaptchaRender />}
                                        {(
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                className={classes.submit}
                                            >
                                                登录
                                            </Button>
                                        )}
                                    </form>
                                {/* <Divider /> */}
                                <div className={classes.link}>
                                        <div className={classes.linkMargn2}>
                                            {UserPwdLogin && (
                                                <Link href={"/"} onClick={setUSMSLoginEvent}>验证码登陆</Link>
                                            )}
                                            {USMSLogin && (
                                                <Link href={"/"} onClick={setUserPwdLoginEvent}>密码登陆</Link>
                                            )}
                                        </div>
                                        <div>
                                            <Link href={"/forget"}></Link>
                                        </div>
                                        <div>
                                            <Link href={"/forget"}></Link>
                                        </div>
                                        <div className={classes.linkMargn2}>
                                            <Link className={classes.linkMargn} href={"/forget"}>忘记密码</Link>
                                            <Link href={"/signup"}>注册账号</Link>
                                        </div>
                                </div>
                        </div>
                        
                        {/* <ICPFooter /> */}
                    </Paper>
                    {!weChatLogin && (
                        <div style={{ textAlign: 'center',overflow:"hidden",marginTop:"20px"}}><Button onClick={setWeChatLoginEvent} >切换至微信登陆</Button></div>
                    )}
                </>
            )}
            {weChatLogin && (
                 <>
                 <Paper className={classes.paper}>
                    <WXLogin wxLogin={wxLogin.bind(this)}/>
                </Paper>
                <div style={{ textAlign: 'center',overflow:"hidden",marginTop:"20px"}}><Button onClick={setPhoneLoginEvent} >切换至手机号码登陆</Button></div>
                </>
            )}
        </div>
    );
}

export default LoginForm;
