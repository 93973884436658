import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RegIcon from "@material-ui/icons/AssignmentIndOutlined";
import {
    Avatar,
    Button,
    Divider,
    FormControl,
    Input,
    InputLabel,
    Link,
    makeStyles,
    Paper,
    Typography,
    Checkbox,
    FormControlLabel,
    Box
} from "@material-ui/core";
import { toggleSnackbar } from "../../actions/index";
import { useHistory } from "react-router-dom";
import API from "../../middleware/Api";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import { useCaptcha } from "../../hooks/useCaptcha";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: "auto",
        marginTop: "110px",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            width: 400,
            marginLeft: "auto",
            marginRight: "auto",
        },
        marginBottom: 110,
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
            3
        )}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    link: {
        marginTop: "20px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    readFileClass: {
        marginTop: "5px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        fontSize:10
    },
    captchaContainer: {
        display: "flex",
        marginTop: "10px",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    captchaPlaceholder: {
        width: 200,
    },
    buttonContainer: {
        display: "flex",
    },
    authnLink: {
        textAlign: "center",
        marginTop: 16,
    },
    avatarSuccess: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    linkSendSMS:{
        position:"absolute",
        bottom:'10px',
        right:'0px',
        color: "rgba(0,0,0,0.64)",
    },
    linkSendSMSDark:{
        position:"absolute",
        bottom:'10px',
        right:'0px',
        color: "rgba(255,255,255,1)",
    },
}));

function Register() {
    const [input, setInput] = useState({
        phone: "",//手机号码
        verCode:"",//验证码
        nickName:"",//昵称
        password: "",//密码
        password_repeat: "",//确认密码
    });
    const [loading, setLoading] = useState(false);
    const [readFile, setReadFile] = useState(false);
    const [emailActive, setEmailActive] = useState(false);
    const [verification, setVerification] = useState("发送验证码");
    const [verificationStatus, setVerificationStatus] = useState(true);

    const title = useSelector((state) => state.siteConfig.title);
    const regCaptcha = useSelector((state) => state.siteConfig.regCaptcha);

    const dispatch = useDispatch();
    const ToggleSnackbar = useCallback(
        (vertical, horizontal, msg, color) =>
            dispatch(toggleSnackbar(vertical, horizontal, msg, color)),
        [dispatch]
    );
    const history = useHistory();
    const ThemeType = useSelector(
        (state) => state.siteConfig.theme.palette.type
    );
    const handleInputChange = (name) => (e) => {
        setInput({
            ...input,
            [name]: e.target.value,
        });
    };

    const {
        captchaLoading,
        isValidate,
        validate,
        CaptchaRender,
        captchaRefreshRef,
        captchaParamsRef,
    } = useCaptcha();
    const classes = useStyles();
    let timer
    const sendSMSCodeEvent = (e) => {
        e.preventDefault();
        //获取验证码
        if (verificationStatus){
            if (input.phone == null || input.phone == "") {
                ToggleSnackbar("top", "right", "请输入手机号码", "warning");
                return;
            }
            setVerificationStatus(false);
            setLoading(true);
            API.post("/user/SendSMS", {
                PhoneNum: input.phone,
                SMSType: "2",
            }).then((response) => {
                    setLoading(false);
                    if (response.rawData.code === 0) {//短信发送成功
                        let timerCount = 120;
                        setVerification(timerCount+'S重试')
                        timer = setInterval(() => {    
                            setVerification(timerCount+'S重试')
                            timerCount--;
                            if(timerCount === -1){
                                clearInterval(timer);      //清除定时器
                                setVerification('发送验证码')
                                setVerificationStatus(true);
                            }
                        }, 1000);
                    } else{
                        setVerificationStatus(true);
                        //根据状态码来显示错误信息
                        ToggleSnackbar("top", "right", response.msg, "warning");
                    }
            }).catch((error) => {
                setVerificationStatus(true);
                setLoading(false);
                ToggleSnackbar("top", "right", error.message, "warning");
            });
        }
    }
    const handleChange = (event) => {
        setReadFile(event.target.checked);
      };
    const register = (e) => {
        e.preventDefault();
        if(!readFile){
            ToggleSnackbar("top", "right", "请先阅读并同意用户协议和隐私策略", "warning");
            return;
        }
        if (input.password !== input.password_repeat) {
            ToggleSnackbar("top", "right", "两次密码输入不一致", "warning");
            return;
        }
        setLoading(true);
        if (!isValidate.current.isValidate && regCaptcha) {
            validate(() => register(e), setLoading);
            return;
        }
        API.post("/user", {
            userName: input.phone,
            Password: input.password,
            SMSCode: input.verCode,
            NickName: input.nickName,
            ...captchaParamsRef.current,
        }).then((response) => {
            setLoading(false);
            if (response.rawData.code === 0) {
                history.push("/login?username=" + input.phone);
                ToggleSnackbar("top", "right", "注册成功", "success");
            } else {
                ToggleSnackbar("top", "right", response.msg, "warning");
            }
        }).catch((error) => {
            setLoading(false);
            ToggleSnackbar("top", "right", error.message, "warning");
            captchaRefreshRef.current();
        });
    };

    return (
        <div className={classes.layout}>
            <>
                {!emailActive && (
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <RegIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            注册 {title}
                        </Typography>

                        <form className={classes.form} onSubmit={register}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="phone">
                                    手机号码
                                </InputLabel>
                                <Input
                                    id="phone"
                                    name="phone_register"
                                    onChange={handleInputChange("phone")}
                                    autoComplete="off"
                                    value={input.phone}
                                    autoFocus
                                />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="verCode">
                                    验证码
                                </InputLabel>
                                <Input
                                    id="verCode"
                                    type="text"
                                    name="verCode_register"
                                    width = "250px"
                                    onfocus="this.removeAttribute('readonly')"
                                    onChange={
                                        handleInputChange("verCode")
                                    }
                                    autoComplete="off"
                                    value={input.verCode}
                                >
                                </Input>
                                <Link href={"/"} underline="none" className={ThemeType == "light" ? 
                                    classes.linkSendSMS : classes.linkSendSMSDark} 
                                    onClick={sendSMSCodeEvent}>
                                    {verification}
                                </Link>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="nickName">昵称</InputLabel>
                                <Input
                                    name="nickName_register"
                                    onChange={handleInputChange("nickName")}
                                    type="text"
                                    id="nickName"
                                    value={input.nickName}
                                    autoComplete="off"
                                />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">密码</InputLabel>
                                <Input
                                    name="password_register"
                                    onChange={handleInputChange("password")}
                                    type="password"
                                    id="password"
                                    value={input.password}
                                    autoComplete="off"
                                />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">
                                    确认密码
                                </InputLabel>
                                <Input
                                    name="pwdRepeat_register"
                                    onChange={handleInputChange(
                                        "password_repeat"
                                    )}
                                    type="password"
                                    id="pwdRepeat"
                                    value={input.password_repeat}
                                    autoComplete="off"
                                />
                            </FormControl>
                            {regCaptcha && <CaptchaRender />}

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={
                                    loading ||
                                    (regCaptcha ? captchaLoading : false)
                                }
                                className={classes.submit}
                            >
                                注册账号
                            </Button>
                        </form>

                        <Divider />
                        <div className={classes.link}>
                            <div>
                                <Link href={"/login"}>返回登录</Link>
                            </div>
                            <div>
                                <Link href={"/forget"}>忘记密码</Link>
                            </div>
                        </div>
                        <Divider />
                        <div className={classes.readFileClass}>
                            <div>
                                <FormControlLabel
                                    style={{ fontSize:"0.6rem" }}
                                    control={
                                        <Checkbox checked={readFile} size="small" onChange={handleChange} required />
                                    }
                                    label={
                                        <Box component="div" fontSize="0.9rem">
                                           我已阅读并同意 <Link href="https://www.mifengfile.com/terms/user_agreement.html">用户协议</Link> 和 <Link href="https://www.mifengfile.com/terms/privacy_policy.html"> 隐私策略</Link>
                                         </Box>
                                   }
                                />
                            </div>
                            <div>
                                
                            </div>
                        </div>
                    </Paper>
                )}
                {emailActive && (
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatarSuccess}>
                            <EmailIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            邮件激活
                        </Typography>
                        <Typography style={{ marginTop: "10px" }}>
                            一封激活邮件已经发送至您的邮箱，请访问邮件中的链接以继续完成注册。
                        </Typography>
                    </Paper>
                )}
            </>
        </div>
    );
}

export default Register;
