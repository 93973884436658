/* eslint-disable */
import React, { Component } from 'react'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import API from "../../middleware/Api";
import { toggleSnackbar } from "../../actions";
import { withRouter } from "react-router";

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackbar: (vertical, horizontal, msg, color) => {
            dispatch(toggleSnackbar(vertical, horizontal, msg, color));
        },
    };
};

const mapStateToProps = () => {
    return {};
};

class WXBindCompoment extends Component {
  constructor(props) {
    super(props)
    this.state = {
        
    }
  }
  BindWxToUser = (code) => {
    //进行微信绑定
    API.post("/user/WxBind", {
    code: code,
    }).then((response) => {
        if (response.rawData.code === 0) {//绑定成功
            //直接到首页
            this.props.history.push("/home");
            this.props.toggleSnackbar(
                "top",
                "right",
                "绑定微信成功",
                "success"
            );
        }else{
            this.props.toggleSnackbar(
                "top",
                "right",
                response.msg,
                "error"
            );
            this.onOpenLogin()
        }
    }).catch((error) => {
        this.props.toggleSnackbar(
            "top",
            "right",
            error.message,
            "error"
        );
        this.onOpenLogin()
    });
  };

  async componentDidMount() {
    await this.initScript()
    this.onOpenLogin()
    let self = this;
    window.receiveMessageFromIndex = function (event) {
      if (event != undefined) {
        // 发送请求 event.data为微信信息对象 为对象类型再请求接口
        if (typeof event.data === 'object') {
            const WXCode = event.data.code
            //添加openid到当前账户
            self.BindWxToUser(WXCode);
        }
      }
    }
    //监听来自iframe的message事件
    window.addEventListener('message', receiveMessageFromIndex, false)
  }

  componentWillUnmount() {
    window.removeEventListener('message',receiveMessageFromIndex)
  }

  initScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
    document.head.appendChild(script);
  }

  onOpenLogin = () => {
    const timer = setTimeout(() => {
      this.initWX()
      clearTimeout(timer)
    }, 800)
  }
  // redirect_uri: encodeURIComponent('https://pan.blocktest.top/success.html'), // 扫码成功后重定向地址
  initWX() {
    new window.WxLogin({
      self_redirect: true,
      id: 'login_container', 
      appid: 'wx214df6cca860634f',
      scope: 'snsapi_login', // 写死，网页应用暂时只支持这个值
      redirect_uri: encodeURIComponent(window.location.origin + '/BindSuccess.html'), // 扫码成功后重定向地址
      state: "" + (new Date()).getTime(), //随机字符串
      style: 'black',
    })
    this.setState({ isLoading: false })
  }

  render() {
    return (
	    <div onClick={this.onOpenLogin} className='' style={{marginTop:"150px",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",height:"100%"}}>
	      <div style={{ fontSize:"16",color:"#16b998",letterSpacing:"0",textAlign:"center",marginBottom:"20px",marginTop:"10px"}}> 请打开手机微信扫描二维码绑定微信 </div>
        <div id="login_container" style={{ textAlign: 'center' ,width:"300px",height:"300px",overflow:"hidden"}} />
	    </div>
    )
  }
}
const WXBind = connect(
    mapStateToProps,
    mapDispatchToProps
)((withRouter(WXBindCompoment)));

export default WXBind;