import React, { Component } from "react";
import PropTypes from "prop-types";
import StorageIcon from "@material-ui/icons/Storage";
import MemoryIcon from "@material-ui/icons/Memory";
import { connect } from "react-redux";
import API from "../../middleware/Api";
import { sizeToString } from "../../utils";
import { toggleSnackbar } from "../../actions";

import {
    withStyles,
    LinearProgress,
    Typography,
    Divider,
    Tooltip,
} from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import Link from "@material-ui/core/Link";
import { withRouter } from "react-router";

const mapStateToProps = (state) => {
    return {
        refresh: state.viewUpdate.storageRefresh,
        isLogin: state.viewUpdate.isLogin,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSnackbar: (vertical, horizontal, msg, color) => {
            dispatch(toggleSnackbar(vertical, horizontal, msg, color));
        },
    };
};

const styles = (theme) => ({
    iconFix: {
        marginLeft: "32px",
        marginRight: "17px",
        color: theme.palette.text.secondary,
        marginTop: "2px",
    },
    iconFix2: {
        marginLeft: "32px",
        marginRight: "17px",
        color: theme.palette.text.secondary,
        marginTop: "0px",
    },
    textFix: {
        padding: " 0 0 0 16px",
    },
    storageContainer: {
        display: "flex",
        marginTop: "15px",
        textAlign: "left",
        marginBottom: "11px",
    },
    storageContainer2: {
        display: "flex",
        marginTop: "10px",
        textAlign: "left",
        marginBottom: "10px",
        alignItems:"center",
    },
    detail: {
        width: "100%",
        marginRight: "35px",
    },
    info: {
        width: "131px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        [theme.breakpoints.down("xs")]: {
            width: "162px",
        },
        marginTop: "5px",
    },
    bar: {
        marginTop: "5px",
    },
    stickFooter: {
        backgroundColor: theme.palette.background.paper,
    },
});

class StorageBarCompoment extends Component {
    state = {
        percent: 0,
        used: null,
        total: null,
        store:0,
        share_score:0,
        showExpand: false,
        showExpandJiFen: false,
    };

    firstLoad = true;

    componentDidMount = () => {
        if (this.firstLoad && this.props.isLogin) {
            this.firstLoad = !this.firstLoad;
            this.updateStatus();
        }
    };

    componentWillUnmount() {
        this.firstLoad = false;
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (
            (this.props.isLogin && this.props.refresh !== nextProps.refresh) ||
            (this.props.isLogin !== nextProps.isLogin && nextProps.isLogin)
        ) {
            this.updateStatus();
        }
    };

    updateStatus = () => {
        let percent = 0;
        API.get("/user/storage")
            .then((response) => {
                if (response.data.used / response.data.total >= 1) {
                    percent = 100;
                    this.props.toggleSnackbar(
                        "top",
                        "right",
                        "您的已用容量已超过容量配额，请尽快删除多余文件或购买容量",
                        "warning"
                    );
                } else {
                    percent = (response.data.used / response.data.total) * 100;
                }
                this.setState({
                    percent: percent,
                    used: sizeToString(response.data.used),
                    total: sizeToString(response.data.total),
                    score:response.data.score,
                    share_score:response.data.share_score,
                });
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {});
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div
                        onMouseEnter={() => this.setState({ showExpandJiFen: true })}
                        onMouseLeave={() => this.setState({ showExpandJiFen: false })}
                        className={classes.stickFooter}
                    >
                        <Divider />
                        <ButtonBase onClick={() => this.props.history.push("/quota")}>
                            <div className={classes.storageContainer2}>
                                <MemoryIcon className={classes.iconFix2} />
                                <div className={classes.detail}>
                                    积分{" "}{(this.state.score + this.state.share_score).toFixed(2)}{" "}
                                    {this.state.showExpandJiFen && (
                                        <Link href={"/buy"} color={"secondary"}>
                                            充值
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </ButtonBase>
                </div>
                <div
                        onMouseEnter={() => this.setState({ showExpand: true })}
                        onMouseLeave={() => this.setState({ showExpand: false })}
                        className={classes.stickFooter}
                    >
                        <Divider />
                        <ButtonBase onClick={() => this.props.history.push("/quota")}>
                            <div className={classes.storageContainer}>
                                <StorageIcon className={classes.iconFix} />
                                <div className={classes.detail}>
                                    存储空间{"   "}
                                    {this.state.showExpand && (
                                        <Link href={"/buy"} color={"secondary"}>
                                            扩容
                                        </Link>
                                    )}
                                    <LinearProgress
                                        className={classes.bar}
                                        color="secondary"
                                        variant="determinate"
                                        value={this.state.percent}
                                    />
                                    <div className={classes.info}>
                                        <Tooltip
                                            title={
                                                "已使用 " +
                                                (this.state.used === null
                                                    ? " -- "
                                                    : this.state.used) +
                                                ", 共 " +
                                                (this.state.total === null
                                                    ? " -- "
                                                    : this.state.total)
                                            }
                                            placement="top"
                                        >
                                            <Typography
                                                variant="caption"
                                                noWrap
                                                color="textSecondary"
                                            >
                                                {this.state.used === null
                                                    ? " -- "
                                                    : this.state.used}
                                                {" / "}
                                                {this.state.total === null
                                                    ? " -- "
                                                    : this.state.total}
                                            </Typography>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </ButtonBase>
                </div>
            </div>
            
        );
    }
}

StorageBarCompoment.propTypes = {
    classes: PropTypes.object.isRequired,
};

const StorageBar = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(withRouter(StorageBarCompoment)));

export default StorageBar;
